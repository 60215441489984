.parallaxMenu {
    background:#141414;
    min-height: 430px;
    background-attachment:local;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.9;
}

.menu-h {
    color: #fff;
    font-size: 46px;
}


.menu-o {
    height: 940px;
}

.col-hr {
    background-color: #fff;
    border: 1px solid #fff;
}
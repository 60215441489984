.nomar {
  padding:0; margin:0;
}
.parallax {
  background-image: url("./images/a.jpg");
  min-height: 930px; 
  background-color: antiquewhite;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallaxb {
  background-image: url("./images/b.jpg");
  opacity: 1;
  min-height: 700px; 
  background-color: antiquewhite;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallaxc {
  background-image: url("./images/c.jpg");
  min-height: 700px; 
  background-color: antiquewhite;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bgred {
  background-color:rgb(143, 7, 7);
}
.content-m{
  height:383px;
}
.con-h {
  font-size:36px;
  color:#fff;
  font-weight: 500;
}
.con-b {
  font-size:22px;
  color:#fff;
}
.content-px{
  height:383px;
  vertical-align: middle;
}
.parallax-h {
  padding-top: 80px;
  font-size: 66px;
  font-weight: bold;
  color: #fff;
  line-height: 82px;
  text-shadow: 2px 2px #6d6d6d57;
}

.parallax-c {
  font-size: 28px;
  color: #fff;
}
.bgnone {
  background-color: none;
}
.logo{
  font-size: 34px;
}
.innerH {
  margin-bottom: 0 0 420px 0;
  font-size: 22px;
}
.navf {
  font-size: 22px;
  color: #fff;
}
.fresh-h{
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  padding: 20px 0;
}
.fresh-b{
  color: #fff;
  font-size: 18px;
}
.features-head {
  color: #fff;
}
.features-body {
  color: #fff;
}
.feature-th{
  color: #fff;
  padding: 40px 0 0 0;
  font-size: 38px;
}
.img-rounded{
 border-radius: 30px;
 width: 300px;
 height: 200px;
}
.nav-font {
  color: #fff;
}
.footer {
  height: 310px;
  background-color: rgb(20, 20, 20);
}
.footer-text {
  color:rgb(230, 234, 238);
  text-align: center;
}
.trip{
  height: 30px;
  width: 30px;
}

.yelp{
  height: 20px;
  width: 40px;
}

.fb{
  height: 30px;
  width: 30px;
}

.lagyal {
  text-decoration: none;
  color: rgb(168, 208, 243);
}

.rights-f{
  line-height: 34px;
}
.parallaxLocation {
        background-image: url("../../images/location.jpg");
        min-height: 430px; 
        background-color: antiquewhite;
        background-attachment:local;
        background-position:center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.9;
}

.location-h {
    color: #fff;
    font-size: 46px;
}

.add {
    font-size: 22px;
}

.phone-l {
    text-decoration: none;
    font-weight: bold;
    color: rgb(13, 141, 141);
}

.phone-l:hover {
    text-decoration: none;
    font-weight: bold;
    color: rgb(17, 194, 194);
}


.parallaxGallery {
    min-height: 850px; 
    background-color: #141414;
    background-attachment:local;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.9;
}

.gallery {
    height: 790px;
    text-align: center;
}